import Vuex from 'vuex'

const store = new Vuex.Store({
    state: {
        token: localStorage.getItem('token') ? localStorage.getItem('token') : ''
    },
    mutations: {
        setToken (state, token) {
            state.token = token;
            localStorage.setItem('token',token)
        },
        delToken (state) {
            state.token = ''
            localStorage.removeItem('token')
        }
    }
});

export default store;
