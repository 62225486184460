<template>
  <div class="main">
    <div class="login">
      <el-image style="width: 150px;height: 150px" :src="require('../../../public/logo.png')" fit="scale" />
      <div class="title">
        暨南大学智能基座 · 活动签到系统后台
      </div>
      <el-input style="width: 80%;height: 40px;font-size: medium;margin-top: 2vh" prefix-icon="Document" v-model="account" placeholder="请输入账号" clearable />
      <el-input style="width: 80%;height: 40px;font-size: medium;margin-top: 2vh" prefix-icon="Lock" v-model="password" placeholder="请输入密码" show-password />
      <div class="verify">
        <el-input style="height: 40px;font-size: medium" prefix-icon="Key" v-model="inputVerifyCode" placeholder="请输入验证码" clearable />
        <div @click="refreshCode"><VerifyCode :identify-code="verifyCode"></VerifyCode></div>
      </div>
      <el-button @click="login" class="btn" type="primary" round>登 录</el-button>
    </div>
  </div>
</template>

<style scoped>
.main {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-top: 20vh;
}

.main .login {
  width: 30vw;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: start;
  flex-direction: column;
  display: flex;
}

.main .login .title {
  font-size: 20px;
  font-weight: bolder;
}

.main .login .verify {
  margin-top: 2vh;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.main .login .btn {
  margin-top: 4vh;
  margin-bottom: 4vh;
  width: 8vw;
  height: 5vh;
  font-size: large;
  font-weight: bolder;
}

</style>

<script>
import VerifyCode from "@/components/AdminPanel/Utils/VerifyCode";
import {ElNotification, ElLoading} from 'element-plus'
import requests from "@/api/request";
export default {
  components: {
    VerifyCode
  },
  data(){
    return{
      account: '',
      password: '',
      verifyCode: '',
      inputVerifyCode: ''
    }
  },
  mounted() {
    this.refreshCode()
  },
  methods:{
    warnMsg(msg) {
      ElNotification({
        title: '错误！',
        message: msg,
        type: 'warning',
      })
    },
    refreshCode () {
      this.verifyCode = ''
      for (let i = 0; i < 4; i++) {
        this.verifyCode += Math.floor(Math.random() * 9).toString()
      }
    },
    async login() {
      if (this.account === '') {
        this.warnMsg('请输入账号！');
        return;
      }
      if (this.password === '') {
        this.warnMsg('请输入密码！');
        return;
      }
      if (this.inputVerifyCode === '') {
        this.warnMsg('请输入验证码！');
        return;
      }
      if (this.inputVerifyCode !== this.verifyCode) {
        ElNotification({
          title: '错误！',
          message: '验证码错误！请重新输入！',
          type: 'error',
        })
        this.refreshCode();
        return;
      }
      //do login
      const loading = ElLoading.service({
        lock: true,
        text: '登录中...',
        background: 'rgba(0,0,0,0.7)'
      })
      await requests.post('/admin/login',{
        account: this.account,
        password: this.password
      }).then((res) => {
        loading.close()
        if(res.code === 0) {
          ElNotification({
            title: '错误！',
            message: res.msg,
            type: 'error'
          })
        }
        else {
          ElNotification({
            title: '登录成功！',
            message: '欢迎回来，'+res.data.userName+"！",
            type: 'success',
          })
          //set token
          this.$store.commit('setToken',res.data.token)
          console.log(localStorage.getItem('token'))
          this.$router.push('/index')
        }
      }).catch((error) => {
        loading.close()
        ElNotification({
          title: '错误！请求接口失败！',
          message: error,
          type: 'error'
        })
        console.log(error)
      })

    }
  },
  beforeCreate() {
    document
        .querySelector('body')
        .setAttribute('style', 'background-color:whitesmoke');
  },
};
</script>
