<template>
  <div class="box">
    <el-container style="height: 100%; border: 1px solid #eee">
      <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
        <el-menu router :default-active="this.$router.currentRoute.value.path" :default-openeds="['1','2', '3', '4', '5', '6']">
          <el-sub-menu index="1">
            <template #title>管理员用户</template>
            <el-menu-item-group>
              <template #title>管理员用户面板</template>
              <el-menu-item index="/admin-users"><el-icon><UserFilled /></el-icon>所有用户</el-menu-item>
              <el-menu-item index="/add-user"><el-icon><Edit /></el-icon>添加用户</el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>
          <el-sub-menu index="2">
            <template #title>活动管理</template>
            <el-menu-item-group>
              <template #title>活动管理面板</template>
              <el-menu-item index="/all-activities"><el-icon><Guide /></el-icon>所有活动</el-menu-item>
              <el-menu-item index="/add-activity"><el-icon><Edit /></el-icon>添加活动</el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>
        </el-menu>
      </el-aside>

      <el-container>
        <el-header style="text-align: center; font-size: larger; font-weight: bolder">
          <span>暨南大学智能基座 · 活动签到系统后台</span>
        </el-header>

        <el-main>
          <slot>Default</slot>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<style>
.box{
  height: 100%;
}
.el-header {
  background-color: #c6e2ff;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
</style>

<script >
export default {
  components: {

  },
  data(){
    return{
      activeMenu: ''
    }
  },
  mounted() {
    console.log(this.$router.currentRoute.value.path)
  },
  methods:{
    goto(path){
      this.$router.push(path);
    },
    selectMenu(index) {
      this.activeMenu = index
      window.localStorage.setItem('activeMenu', this.activeMenu)
    },
  },
  beforeCreate() {
    document
        .querySelector('body')
        .setAttribute('style', 'background-color:whitesmoke')
  },
};
</script>
