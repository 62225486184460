<template>
  <PanelMenus>
    <el-container style="flex-direction: column;display: flex">
      <div style="font-weight: bolder;font-size: large">所有管理员用户</div>
      <el-table :data="tableData" border
                table-layout="auto" style="margin-top: 2vh;margin-bottom: 2vh" >
        <el-table-column prop="id" label="唯一ID" />
        <el-table-column prop="userName" label="用户名" />
        <el-table-column prop="account" label="账户" />
        <el-table-column prop="password" label="密码" />
        <el-table-column align="center" label="操作">
          <template #default="scope">
            <el-button size="default" icon="Edit" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button icon="Delete" size="default" type="danger" @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-container>
  </PanelMenus>

</template>

<style>

</style>

<script>
import PanelMenus from "@/components/AdminPanel/Utils/PanelMenus";
import requests from "@/api/request";
import {ElNotification} from "element-plus";

export default {
  components: {
    PanelMenus
  },
  data(){
    return{
      tableData: []
    }
  },
  mounted() {
    //query all users
    requests.get('/admin/queryAllUsers').then((res) => {
      if (res.code === 0) {
        ElNotification({
          title: '错误！',
          message: res.msg,
          type: 'error'
        })
      }
      else {
        this.tableData = res.data.adminUsers
      }
    }).catch((error) => {
      ElNotification({
        title: '错误！请求接口失败！',
        message: error,
        type: 'error'
      })
    })

  },
  methods:{
    handleEdit(index, row) {
      console.log(index, row.userName)
    },
    handleDelete(index, row) {
      console.log(index, row)
    }
  },
  beforeCreate() {
    document
        .querySelector('body')
        .setAttribute('style', 'background-color:whitesmoke')
  },
};
</script>
