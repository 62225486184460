<template>
  <PanelMenus>
    <el-main>
      <el-empty description="欢迎使用本系统，请选择左边菜单进行操作！"></el-empty>
    </el-main>
  </PanelMenus>

</template>

<style>

</style>

<script>
import PanelMenus from "@/components/AdminPanel/Utils/PanelMenus";
export default {
  components: {
    PanelMenus
  },
  data(){
    return{

    }
  },
  mounted() {

  },
  methods:{

  },
  beforeCreate() {
    document
        .querySelector('body')
        .setAttribute('style', 'background-color:whitesmoke')
  },
};
</script>
