<template>
  <PanelMenus>
    <div style="font-weight: bolder;font-size: large;align-items: center">添加管理员用户</div>
    <el-container style="align-items: start;margin-top: 3vh;flex-direction: column;display: flex">
      <div class="tag">用户名</div>
      <el-input prefix-icon="UserFilled" style="margin-top: 1vh;height: 4vh" class="input-box" v-model="userName" placeholder="请输入用户名" clearable />
    </el-container>
    <el-container style="align-items: start;margin-top: 1vh;flex-direction: column;display: flex">
      <div class="tag">账号</div>
      <el-input prefix-icon="Tickets" style="margin-top: 1vh;height: 4vh" class="input-box" v-model="account" placeholder="请输入账号" clearable />
    </el-container>
    <el-container style="align-items: start;margin-top: 1vh;flex-direction: column;display: flex">
      <div class="tag">密码</div>
      <el-input prefix-icon="Lock" style="margin-top: 1vh;height: 4vh" class="input-box" v-model="password" placeholder="请输入密码" clearable />
    </el-container>
    <el-button @click="submit" class="btn" type="primary" round><el-icon style="margin-right: 0.2vw"><DocumentChecked /></el-icon>立即创建</el-button>
  </PanelMenus>
</template>

<script>
import PanelMenus from "@/components/AdminPanel/Utils/PanelMenus";
import {ElLoading, ElNotification} from "element-plus";
import requests from "@/api/request";
export default {
  components: {
    PanelMenus
  },
  data(){
    return{
      userName: '',
      account: '',
      password: ''
    }
  },
  mounted() {

  },
  methods:{
    warnMsg(msg) {
      ElNotification({
        title: '错误！',
        message: msg,
        type: 'warning',
      })
    },
    async submit() {
      if (this.userName === '') {
        this.warnMsg('请输入用户名！');
        return;
      }
      if (this.account === '') {
        this.warnMsg('请输入账号！');
        return;
      }
      if (this.password === '') {
        this.warnMsg('请输入密码！');
        return;
      }
      //do add
      const loading = ElLoading.service({
        lock: true,
        text: '添加中...',
        background: 'rgba(0,0,0,0.7)'
      })
      await requests.post('/admin/addUser?account='+this.account+'&password='+this.password+'&userName='+this.userName).then((res) => {
        loading.close()
        if(res.code === 0) {
          ElNotification({
            title: '错误！',
            message: res.msg,
            type: 'error'
          })
        }
        else {
          ElNotification({
            title: '创建成功！',
            message: '用户 '+res.data.adminUser.userName+" 创建成功！",
            type: 'success',
          })
        }
      }).catch((error) => {
        ElNotification({
          title: '错误！请求接口失败！',
          message: error,
          type: 'error'
        })
      })
      this.account = ''
      this.userName = ''
      this.password = ''
    }
  },
  beforeCreate() {
    document
        .querySelector('body')
        .setAttribute('style', 'background-color:whitesmoke')
  },
};
</script>

<style scoped>
  .tag {
    font-size: medium;
  }
  .input-box {
    width: 30%;
  }
  .btn {
    margin-top: 3vh;
    margin-bottom: 3vh;
    width: 8vw;
    height: 5vh;
    font-size: large;
    font-weight: bolder;
  }
</style>