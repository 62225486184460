import { createApp } from 'vue'
import App from './App.vue'
import {createRouter, createWebHashHistory} from 'vue-router'
import Vuex from 'vuex'
import store from './store'
import requests from "@/api/request";

//element plus
import ElementUI, {ElNotification} from 'element-plus'
import 'element-plus/dist/index.css'
import * as icons from '@element-plus/icons'

//pages
import SignInIndex from "@/components/SignIn/SignInIndex";
import PanelLogin from "@/components/AdminPanel/PanelLogin";
import PanelIndex from "@/components/AdminPanel/PanelIndex";
import PanelAdminUsers from "@/components/AdminPanel/PanelAdminUsers";
import PanelAddUser from "@/components/AdminPanel/PanelAddUser";
import PanelAllActivities from "@/components/AdminPanel/PanelAllActivities";
import PanelAddActivity from "@/components/AdminPanel/PanelAddActivity";
//define routers
const routes = [
    {path: '/', redirect: '/login'},
    {path: '/login', component: PanelLogin},
    {path: '/index', component: PanelIndex},
    {path: '/admin-users', component: PanelAdminUsers},
    {path: '/add-user', component: PanelAddUser},
    {path: '/all-activities', component: PanelAllActivities},
    {path: '/add-activity', component: PanelAddActivity},
    {path: '/sign', component: SignInIndex}
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to,from,next) => {
    let token = localStorage.getItem('token');
    //如果token不存在或前往登录页，直接放行
    if (to.path === '/login' || to.path === '/sign') {
        next();
        return
    }
    if (!token) {
        ElNotification({
            title: '错误！请求Token有效性失败！',
            message: "Token已被清除！",
            type: 'error'
        })
        next('/login')
    }
    else {
        //token存在，检查是否有效
        requests.post("/token/verifyToken",{token: token}).then((res) => {
            if (res.code === 0) {
                ElNotification({
                    title: '错误！Token有效性校验失败！',
                    message: res.msg,
                    type: 'error'
                })
                next('/login')
            }
            else {
                //token 有效
                next()
            }
        }).catch((error) => {
            ElNotification({
                title: '错误！请求Token有效性失败！',
                message: error,
                type: 'error'
            })
            next('/login')
        })
    }
})

//create
const app = createApp(App)
app.use(ElementUI).use(router).use(Vuex).use(store)
Object.keys(icons).forEach(key => {
    app.component(key, icons[key])
})
app.mount('#app')
