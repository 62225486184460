<template>
  <div class="main">
    <div class="card">
      <div class="top-items">
        <div class="imgbox">
          <img src="../../../public/logo.png" class="logo"/>
        </div>
        <!--Titles-->
        <div class="title">
          暨南大学智能基座 · 活动签到系统
        </div>
        <el-divider border-style="dashed" />
        <div class="done-img-box">
          <img src="../../../public/error.png" class="done-img" />
        </div>
        <div class="done-msg">
          出现错误！活动不存在或请求不合法！
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {ElDivider} from 'element-plus'
export default defineComponent({
  components: {
    ElDivider
  },
  data() {
    return {

    }
  },
  methods: {

  },
  mounted() {

  }
})
</script>

<style scoped>
#app {
  background-color: whitesmoke;
}

.main {
  display: flex;
  justify-content: center;
  align-items: start;
  height: 100vh;
}

.main .card {
  background-color: white;
  border-radius: 20px;
  width: 90vw;
  margin-top: 2vh;
  flex-direction: column;
  display: flex;
}

.main .card .top-items {
  align-items: center;
  justify-content: start;
  display: flex;
  flex-direction: column;
}

.main .card .top-items .imgbox {
  width: 150px;
  height: 150px;
}

.main .card .top-items .imgbox .logo {
  width: 100%;
  height: 100%;
}

.main .card .top-items .title {
  font-weight: bolder;
  font-size: larger;
}

.main .card .done-img-box {
  width: 70vw;
  margin-top: 2vh;
}

.main .card .done-img {
  width: 100%;
  height: 100%;
}

.main .card .done-msg {
  text-align: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
  font-weight: bolder;
  color: red;
}

</style>
