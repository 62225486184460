<template>
  <SignInError v-if="dataLegal === false" />
  <SignInMobile v-else-if="device === 0" :activity-name="activityName"
                :has-done="hasDone" :in-time="inTime" :time="time" :activity-id="activityId" />
  <SignInPC v-else-if="device === 1" :activity-name="activityName"
                :has-done="hasDone" :in-time="inTime" :time="time" :activity-id="activityId" />
</template>

<script>
import {defineComponent} from "vue";
import SignInMobile from "@/components/SignIn/SignInMobile";
import SignInError from "@/components/SignIn/SignInError";
import SignInPC from "@/components/SignIn/SignInPC";
import requests from "@/api/request";
import {ElMessage} from "element-plus";
export default defineComponent({
  components: {
    SignInMobile,
    SignInPC,
    SignInError
  },
  data() {
    return {
      device: 0, //0 - mobile, 1 - pc / pad
      dataLegal: false,
      activityName: "",
      activityId: 0,
      hasDone: false,
      inTime: true,
      time: ""
    }
  },
  methods: {
    isMobile() {
      return navigator.userAgent.match(/(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    }
  },
  mounted() {
    //检测设备
    if (this.isMobile()) {
      this.device = 0;
      console.log("device: mobile")
    }
    else {
      this.device = 1;
      console.log("device: pc")
    }
    //检测参数是否合法
    let data = this.$router.currentRoute.value.query;
    let id = data.id;
    if (JSON.stringify(data) === '{}' || id === undefined) {
      this.dataLegal = false;
      return;
    }
    else this.dataLegal = true;
    // 发请求检查任务是否存在
    requests.post("/signInActivity/check",{uniqueCode: id}).then((res) => {
      if (res.code === 0) {
        this.dataLegal = false;
      }
      else {
        this.activityName = res.data.activityName
        this.hasDone = res.data.hasDone
        this.activityId = res.data.activityId
        this.inTime = res.data.inTime
        this.time = res.data.time
        if (this.hasDone === false && this.inTime === true) {
          ElMessage({
            message: '可以进行签到！',
            type: 'success',
          })
        }
        else {
          console.log(res)
          ElMessage({
            message: res.data.msg,
            type: 'warning',
          })
        }
      }
    }).catch((error) => {
      ElMessage({
        message: '请求接口失败！',
        type: 'error',
      })
      console.log(error)
    })
  }
})
</script>

<style scoped>

</style>