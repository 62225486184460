<template>
  <div class="main">
    <div class="card">
      <div class="top-items">
        <div class="imgbox">
          <img src="../../../public/logo.png" class="logo"/>
        </div>
        <!--Titles-->
        <div class="title">
          暨南大学智能基座 · 活动签到系统
        </div>
        <div class="subTitle" style="color: #79bbff;font-weight: bold">
          {{ activityName }}
        </div>
      </div>
      <el-divider border-style="dashed" />
      <!--finished?-->
      <div v-if="hasDone || completed" class="done">
        <div class="done-img-box">
          <img src="../../../public/done.png" class="done-img" />
        </div>
        <div class="done-msg">
          签到成功！你是第 <div style="color: #79bbff;font-weight: bold" class="rank">{{ rank }}</div> 位签到的人！
        </div>
      </div>
      <div v-else>
        <div class="forum">
          <div class="tipTitle">活动时间：</div>
          <div class="tip">{{time}}</div>
          <!--Time Expired?-->
          <div v-if="!inTime" class="time">
            <div class="time-img-box">
              <img src="../../../public/not_in_time.png" class="time-img" />
            </div>
            <div class="time-msg">
              不在活动时间内，无法签到！
            </div>
          </div>
          <div v-else>
            <div class="tipTitle">所属院系：</div>
            <el-select class="select-box" v-model="department" placeholder="请选择院系">
              <el-option v-for="item in allDepartments" :key="item" :value="item" />
            </el-select>
            <div class="tipTitle">所属专业：</div>
            <el-input class="input-box" v-model="major" placeholder="请输入你的专业" clearable />
            <div class="tipTitle">你的姓名：</div>
            <el-input class="input-box" v-model="name" placeholder="请输入你的姓名" clearable />
            <div class="tipTitle">你的学号：</div>
            <el-input class="input-box" v-model="number" placeholder="请输入你的学号" clearable />
            <div class="submit-btn">
              <el-button @click="submit" type="success" plain>提交签到</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {ElSelect, ElInput, ElDivider, ElButton, ElMessage, ElMessageBox, ElLoading} from 'element-plus'
import requests from "@/api/request";
export default defineComponent({
  components: {
    ElSelect,
    ElInput,
    ElDivider,
    ElButton
  },
  props: {
    hasDone: {
      type: Boolean,
      default: false
    },
    activityName: {
      type: String,
      default: 'Undefined Activity Name'
    },
    inTime: {
      type: Boolean,
      default: false
    },
    time: {
      type: String,
      default: 'Undefined Available Time'
    },
    activityId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      allDepartments: ['智能科学与工程学院/人工智能产业学院','人文学院','翻译学院','国际商学院','包装工程学院','国际能源学院'],
      department: '',
      name: '',
      major: '',
      number: '',
      completed: false,
      rank: 0
    }
  },
  methods: {
    submit() {
      if (this.department === '') {
        ElMessage({
          message: '请选择你的院系！',
          type: 'warning',
        })
        return;
      }
      if (this.major === '') {
        ElMessage({
          message: '请输入你的专业！',
          type: 'warning',
        })
        return;
      }
      if (this.name === '') {
        ElMessage({
          message: '请输入你的姓名！',
          type: 'warning',
        })
        return;
      }
      if (this.number === '') {
        ElMessage({
          message: '请输入你的学号！',
          type: 'warning',
        })
        return;
      }
      //submit
      ElMessageBox.confirm(
          '你确定要以填写的信息签到吗？一旦确定无法修改且无法二次签到！',
          '你确定吗？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(async () => {
        const loading = ElLoading.service({
          lock: true,
          text: '签到中...',
          background: 'rgba(0,0,0,0.7)'
        })
        await requests.post("/signInActivity/sign", {
          activityId: this.activityId,
          department: this.department,
          major: this.major,
          name: this.name,
          number: this.number
        }).then((res) => {
          loading.close()
          if (res.code === 0) {
            ElMessage({
              message: res.msg,
              type: 'error',
            })
          } else {
            ElMessage({
              message: "恭喜你，签到成功！",
              type: 'success',
            })
            this.completed = true
            this.rank = res.data.rank
          }
        }).catch((error) => {
          loading.close()
          ElMessage({
            message: '错误，请求接口失败！',
            type: 'error',
          })
          console.log(error)
        })
      })
    }
  },
  mounted() {

  },
  beforeCreate() {
    document
        .querySelector('body')
        .setAttribute('style', 'background-color:whitesmoke')
  }
})
</script>

<style scoped>

.main {
  display: flex;
  justify-content: center;
  align-items: start;
  height: 100vh;
}

.main .card {
  background-color: white;
  border-radius: 20px;
  width: 90vw;
  margin-top: 2vh;
  flex-direction: column;
  display: flex;
}

.main .card .top-items {
  align-items: center;
  justify-content: start;
  display: flex;
  flex-direction: column;
}

.main .card .top-items .imgbox {
  width: 150px;
  height: 150px;
}

.main .card .top-items .imgbox .logo {
  width: 100%;
  height: 100%;
}

.main .card .top-items .title {
  font-weight: bolder;
  font-size: larger;
}

.main .card .top-items .subTitle {
  margin-top: 1vh;
  font-size: medium;
}

.main .card .done {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.main .card .done .done-img-box {
  width: 70vw;
  margin-top: 2vh;
}

.main .card .done .done-img {
  width: 100%;
  height: 100%;
}

.main .card .done .done-msg {
  text-align: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
  font-weight: bolder;
  color: #42b983;
  flex-direction: row;
  display: flex;
}

.main .card .done .done-msg .rank {
  color: #79bbff;
  font-size: larger;
  margin-left: 10px;
  margin-right: 10px;
}

.main .card .done .done-msg .rank {
  color: aqua;
  font-size: larger;
}

.main .card .forum {
  flex-direction: column;
  display: flex;
  margin-left: 6.5vw;
  margin-top: -1.5vh;
}

.main .card .tipTitle {
  color: #535152;
  font-weight: bolder;
  margin-top: 1.5vh;
}

.main .card .tip {
  color: #535152;
  font-weight: bold;
  font-size: small;
  margin-top: 0.5vh;
}

.main .card .time {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.main .card .time .time-img-box {
  width: 50%;
  height: 50%;
  display: flex;
  margin-top: 2vh;
}

.main .card .time .time-img-box .time-img {
  width: 100%;
  height: 100%;
}

.main .card .time .time-msg {
  color: red;
  font-weight: bolder;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.main .card .forum .select-box {
  margin-top: 1vh;
  width: 93%;
}

.main .card .forum .input-box {
  margin-top: 1vh;
  width: 93%;
}

.main .card .forum .submit-btn {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-top: 3vh;
  margin-bottom: 3vh;
  width: 93%;
}
</style>
