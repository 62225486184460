<template>
  <router-view />
</template>

<script>
import {defineComponent} from "vue";
export default defineComponent({
  data() {
    return {

    }
  },
  methods: {

  },
  mounted() {

  }
})
</script>

<style scoped>

</style>
