<template>
  <PanelMenus>
    <el-container style="flex-direction: column;display: flex">
      <div style="font-weight: bolder;font-size: large">所有活动</div>
      <el-table :data="tableData" border
                table-layout="auto" style="margin-top: 2vh;margin-bottom: 2vh" >
        <el-table-column prop="id" label="唯一ID" />
        <el-table-column prop="uniqueCode" label="唯一识别码" />
        <el-table-column prop="name" label="活动名称" />
        <el-table-column prop="startTime" label="活动开始时间" />
        <el-table-column prop="endTime" label="活动结束时间" />
        <el-table-column align="center" label="查看签到二维码">
          <template #default="scope">
            <el-button size="default" type="primary" icon="Paperclip" @click="showQrCode(scope.row.name,scope.row.uniqueCode)">点击查看</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="查看已签到成员">
          <template #default="scope">
            <el-button size="default" type="primary" icon="User" @click="seeMembers(scope.row)">点击查看</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template #default="scope">
            <el-button size="default" icon="Edit" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button icon="Delete" size="default" type="danger" @click="deleteActivity(scope.row.uniqueCode)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--show qr code-->
      <el-dialog v-model="displayCode" title="使用手机扫描下方二维码以签到" width="30%" center>
        <div style="flex-direction: column;align-items: center;display: flex;justify-content: center">
          <div style="margin-bottom: 2vh;font-size: large;font-weight: bold;color: #79bbff">{{tempActivityName}}</div>
          <qrcode-vue :value="tempActivityUrl" size="300" level="H" />
        </div>
      </el-dialog>

      <!--show signed members-->
      <el-dialog v-model="displayMembers" title="查看已签到成员" width="50%" center>
        <div style="flex-direction: column;align-items: center;display: flex;justify-content: center">
          <div style="margin-bottom: 2vh;font-size: large;font-weight: bold;color: #79bbff">{{tempMemberActivityName}}</div>
          <el-table :data="membersTable" border style="width: 100%" height="500" table-layout="auto">
            <el-table-column type="index" />
            <el-table-column prop="department" label="学院" />
            <el-table-column prop="major" label="专业" />
            <el-table-column prop="name" label="姓名" />
            <el-table-column prop="number" label="学号" />
            <el-table-column prop="signedTime" label="签到时间" />
            <el-table-column prop="ip" label="签到IP地址" />
          </el-table>
          <el-button style="margin-top: 2vh" size="default" type="primary" icon="Download" @click="downloadExcel">下载表格</el-button>
        </div>
      </el-dialog>

      <!--show edit activity-->
      <el-dialog v-model="displayEdit" title="编辑活动中..." width="30%">
        <el-container style="align-items: start;margin-top: 3vh;flex-direction: column;display: flex">
          <div class="tag">活动名称</div>
          <el-input prefix-icon="Star" style="margin-top: 1vh;height: 4vh;width: 60%" v-model="tempEditActivityName" placeholder="请输入活动名称" clearable />
        </el-container>
        <el-container style="align-items: start;margin-top: 1vh;flex-direction: column;display: flex">
          <div class="tag">活动开始时间</div>
          <el-date-picker
              style="margin-top: 1vh;height: 4vh;width: 60%"
              v-model="tempEditActivityStartTime"
              type="datetime"
              placeholder="请选择活动开始时间"
              value-format="YYYY-MM-DD HH:mm:ss "
          />
        </el-container>
        <el-container style="align-items: start;margin-top: 1vh;flex-direction: column;display: flex">
          <div class="tag">活动结束时间</div>
          <el-date-picker
              style="margin-top: 1vh;height: 4vh;width: 60%"
              v-model="tempEditActivityEndTime"
              type="datetime"
              placeholder="请选择活动结束时间"
              value-format="YYYY-MM-DD HH:mm:ss "
          />
        </el-container>
        <el-button @click="submitEdit" class="btn" type="primary" style="margin-top: 2vh" round><el-icon style="margin-right: 0.2vw"><DocumentChecked /></el-icon>确认修改</el-button>
      </el-dialog>
    </el-container>
  </PanelMenus>
</template>

<script>
import PanelMenus from "@/components/AdminPanel/Utils/PanelMenus";
import QrcodeVue from 'qrcode.vue'
import {ElLoading, ElNotification, ElMessageBox} from "element-plus";
import requests from "@/api/request";
import exportExcel from "@/api/exportExcel";
export default {
  components: {
    PanelMenus,
    QrcodeVue
  },
  data() {
    return {
      //qr code
      displayCode: false,
      tempActivityName: '',
      tempActivityUrl: '',
      //see members
      displayMembers: false,
      tempMemberActivityName: '',
      membersTable: [],
      //table
      tableData: [],
      //edit activity
      displayEdit: false,
      tempEditActivityUniqueCode: '',
      tempEditActivityName: '',
      tempEditActivityStartTime: '',
      tempEditActivityEndTime: ''
    }
  },
  mounted() {
    requests.get('/activity/queryAllActivities').then((res) => {
      if (res.code === 0) {
        ElNotification({
          title: '错误！',
          message: res.msg,
          type: 'error'
        })
      }
      else {
        this.tableData = res.data.activities
        console.log(res)
      }
    }).catch((error) => {
      ElNotification({
        title: '错误！请求接口失败！',
        message: error,
        type: 'error'
      })
    })
  },
  methods: {
    async seeMembers(row) {
      this.tempMemberActivityName = row.name;
      //TODO request signed users data
      let uniqueCode = row.uniqueCode;
      const loading = ElLoading.service({
        lock: true,
        text: '获取数据中...',
        background: 'rgba(0,0,0,0.7)'
      })
      await requests.get("/signInActivity/querySigned?uniqueCode="+uniqueCode).then((res) => {
        this.membersTable = res.data.signedPeople
        this.displayMembers = true;
        console.log(res.data.signedPeople)
      }).catch((error) => {
        ElNotification({
          title: '错误！请求接口失败！',
          message: error,
          type: 'error'
        })
      })
      loading.close()
    },
    showQrCode(name,uniqueCode) {
      this.tempActivityName = name;
      this.tempActivityUrl = 'https://sign-in.jnumdg.cn/#/sign?id=' + uniqueCode;
      this.displayCode = true;
    },
    downloadExcel() {
      ElNotification({
        title: '准备下载中...',
        message: '正在准备下载 ' + this.tempMemberActivityName + ' 的表格...',
        type: 'info'
      })
      let fields = {
        department: '学院',
        major: '专业',
        name: '姓名',
        number: '学号',
        signedTime: '签到时间',
        ip: '签到IP地址'
      }
      this.displayMembers = false;
      exportExcel(this.membersTable, fields, this.tempMemberActivityName)
      ElNotification({
        title: '下载成功！',
        message: '成功下载 ' + this.tempMemberActivityName + ' 的表格！',
        type: 'success'
      })
    },
    handleEdit(row) {
      this.displayEdit = true;
      this.tempEditActivityUniqueCode = row.uniqueCode;
      this.tempEditActivityName = row.name;
      this.tempEditActivityStartTime = row.startTime;
      this.tempEditActivityEndTime = row.endTime;
    },
    async submitEdit() {
      if (!this.tempEditActivityName || !this.tempEditActivityStartTime || !this.tempEditActivityEndTime) {
        ElNotification({
          title: '错误！',
          message: "请填充完整要修改的活动的信息再提交！",
          type: 'warning',
        })
        return;
      }
      //do
      const loading = ElLoading.service({
        lock: true,
        text: '修改中...',
        background: 'rgba(0,0,0,0.7)'
      })
      await requests.post("/activity/editActivity",{
        uniqueCode: this.tempEditActivityUniqueCode,
        name: this.tempEditActivityName,
        startTime: this.tempEditActivityStartTime,
        endTime: this.tempEditActivityEndTime
      }).then((res) => {
        if (res.code === 0) {
          ElNotification({
            title: '错误，活动修改失败！',
            message: res.msg,
            type: 'error',
          })
        }
        else {
          ElNotification({
            title: '活动修改成功！',
            message: "成功修改活动 " + this.tempEditActivityName + " ！",
            type: 'success',
          })
          this.displayEdit = false;
          //刷新表格数据
          requests.get('/activity/queryAllActivities').then((res) => {
            if (res.code === 0) {
              ElNotification({
                title: '错误！',
                message: res.msg,
                type: 'error'
              })
            }
            else {
              this.tableData = res.data.activities
              console.log(res)
            }
          }).catch((error) => {
            ElNotification({
              title: '错误！请求接口失败！',
              message: error,
              type: 'error'
            })
          })
        }
      }).catch((error) => {
        ElNotification({
          title: '错误，请求接口失败！',
          message: error,
          type: 'error',
        })
      })
      loading.close()
    },
    deleteActivity(uniqueCode) {
      ElMessageBox.confirm(
          '你确定要删除这个活动吗？一旦删除将无法撤销！',
          '警告',
          {
            confirmButtonText: '确定',
            cancelButtonText: '删除',
            type: 'warning',
          }
      ).then(async () => {
        //do delete
        const loading = ElLoading.service({
          lock: true,
          text: '删除中...',
          background: 'rgba(0,0,0,0.7)'
        })
        await requests.post("/activity/deleteActivity", {uniqueCode: uniqueCode}).then((res) => {
          if (res.code === 0) {
            ElNotification({
              title: '错误！',
              message: res.msg,
              type: 'error'
            })
          }
          else {
            ElNotification({
              title: '删除成功！',
              message: "成功删除该活动！",
              type: 'success'
            })
            //refresh table
            requests.get('/activity/queryAllActivities').then((res) => {
              if (res.code === 0) {
                ElNotification({
                  title: '错误！',
                  message: res.msg,
                  type: 'error'
                })
              }
              else {
                this.tableData = res.data.activities
                console.log(res)
              }
            }).catch((error) => {
              ElNotification({
                title: '错误！请求接口失败！',
                message: error,
                type: 'error'
              })
            })
          }
        }).catch((error) => {
          ElNotification({
            title: '错误，请求接口失败！',
            message: error,
            type: 'error'
          })
        })
        loading.close()
      })
    }
  }
}
</script>

<style scoped>

</style>
