import axios from 'axios'
import Qs from 'qs'

const requests = axios.create({
    baseURL: 'https://api.raymondhqr.top/mdg-sign-in/sign',
    // baseURL: 'http://localhost:8050/sign',
    timeout: 60000,
    headers: {
        'Content-Type': "application/x-www-form-urlencoded;charset=utf-8"
    },
    transformRequest: [function (data) {
        // 把请求的参数的json形式改为表单形式
        return Qs.stringify(data)
    }],
})

requests.interceptors.response.use((res) => {
    return res.data;
}, (error) => {
    console.log('请求失败：'+error)
    return Promise.reject(new Error(error))
})

requests.interceptors.request.use(function (config) {
    let token = localStorage.getItem("token");
    if (token) {
        config.headers.token = token;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
})

export default requests;
