<template>
  <el-config-provider :locale="locale" />
  <PanelMenus>
    <el-container style="flex-direction: column;display: flex">
      <div style="font-weight: bolder;font-size: large">添加活动</div>
      <el-container style="align-items: start;margin-top: 3vh;flex-direction: column;display: flex">
        <div class="tag">活动名称</div>
        <el-input prefix-icon="Star" style="margin-top: 1vh;height: 4vh" class="input-box" v-model="name" placeholder="请输入活动名称" clearable />
      </el-container>
      <el-container style="align-items: start;margin-top: 1vh;flex-direction: column;display: flex">
        <div class="tag">活动开始时间</div>
        <el-date-picker
            style="margin-top: 1vh;height: 4vh;width: 30%"
            v-model="startTime"
            type="datetime"
            placeholder="请选择活动开始时间"
            value-format="YYYY-MM-DD HH:mm:ss "
        />
      </el-container>
      <el-container style="align-items: start;margin-top: 1vh;flex-direction: column;display: flex">
        <div class="tag">活动结束时间</div>
        <el-date-picker
            style="margin-top: 1vh;height: 4vh;width: 30%"
            v-model="endTime"
            type="datetime"
            placeholder="请选择活动结束时间"
            value-format="YYYY-MM-DD HH:mm:ss "
        />
      </el-container>
      <el-button @click="submit" class="btn" type="primary" round><el-icon style="margin-right: 0.2vw"><DocumentChecked /></el-icon>立即创建</el-button>
    </el-container>
  </PanelMenus>
</template>

<script>
import PanelMenus from "@/components/AdminPanel/Utils/PanelMenus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import {ElLoading, ElNotification} from "element-plus";
import request from "@/api/request";
export default {
  components: {
    PanelMenus
  },
  data() {
    return {
      name: '',
      startTime: '',
      endTime: '',
      locale: zhCn
    }
  },
  methods: {
    warnMsg(msg) {
      ElNotification({
        title: '错误！',
        message: msg,
        type: 'warning',
      })
    },
    async submit() {
      if (this.name === '') {
        this.warnMsg('请输入活动名称！');
        return;
      }
      if (this.startTime === '') {
        this.warnMsg('请选择活动的开始时间！');
        return;
      }
      if (this.endTime === '') {
        this.warnMsg('请选择活动的结束时间！');
        return;
      }
      //do add
      const loading = ElLoading.service({
        lock: true,
        text: '添加中...',
        background: 'rgba(0,0,0,0.7)'
      })
      await request.post('/activity/addActivity?name='+this.name+'&startTime='+this.startTime+'&endTime='+this.endTime).then((res) => {
        if(res.code === 0) {
          ElNotification({
            title: '错误！',
            message: res.msg,
            type: 'error'
          })
        }
        else {
          console.log(res)
          ElNotification({
            title: '创建成功！',
            message: '活动 '+res.data.name+" 添加成功！",
            type: 'success',
          })
        }
      }).catch((error) => {
        ElNotification({
          title: '错误！请求接口失败！',
          message: error,
          type: 'error'
        })
      })
      loading.close()
      this.name = ''
      this.startTime = ''
      this.endTime = ''
    }
  }
}
</script>

<style scoped>
.tag {
  font-size: medium;
}
.input-box {
  width: 30%;
}
.btn {
  margin-top: 3vh;
  margin-bottom: 3vh;
  width: 8vw;
  height: 5vh;
  font-size: large;
  font-weight: bolder;
}
</style>